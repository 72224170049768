@import "bootstrap/bootstrap";

// Default layout
body {
  padding-bottom: 0px;
  font-family: 'DM Sans', Helvetica, Arial, sans-serif;
}

h1, h2, h3, h4, h5, h6 {
  font-family: 'DM Serif Text', Georgia, serif;
}

h1 {
  font-weight: 700;
}

h2 {
  font-weight: 600; /* Semi-bold */
}

p {
  font-weight: 400; /* Regular/normal */
}

.light-text {
  font-weight: 300; /* Light */
}

// Footer
.site-footer {
  margin-top: 0;
  padding-top: 0;
}

.site-footer:before {
  content: '';
  display: block;
  height: 5px;
  background: linear-gradient(to right, #0d2d5c, #c8102e);
  margin-bottom: 2rem;
}

.nav-titles {
  li {
    color: blue;
    font-size: 125%;
  }
}

.ul-footer {
  margin: 0;
  padding: 0;


  .contact-list,
  .social-media-list {
    list-style: none;
    margin: 0;
    padding: 2rem 4rem;
    justify-content: space-between;
  }
  .social-media-list {
    @include media-breakpoint-down(sm) {
      text-align: right;
    }
    @include media-breakpoint-up(md) {
      text-align: right;
    }
  }
  > li {
    display: table-cell;
    width: 1%;
    float: none;
  }
}

.motto {
  @include media-breakpoint-down(sm) {
    text-align: center;
    vertical-align: bottom;
    padding-top: 1em;
  }

  // Small and up
  @include media-breakpoint-up(sm) {
    text-align: right;
  }
}

.navbar-footer {
  background-color: #fff;
  border-bottom: 0;
  padding-top: 20px;
}

.icon {
    > svg {
        display: inline-block;
        width: 16px;
        height: 16px;
        vertical-align: middle;
    }
}

// landing page
.mug {
  img {
    max-width: 300px;
  }
  margin: auto;
  horizontal-align: center;
}

// Publications table
.publications {
  @include media-breakpoint-down(xs) {
    padding-left: 1em;
  }

  li{
    margin-top: 10px;
  }
  li:first-child {
    margin-top: 0;
  }
}

// vita table
.vita {
  margin-top: -20px;
  td {
    padding: 5px;
  }

  .category {
    color: #4D4D4D;
    border-bottom: 2px solid #888;
    padding: 0px;

    h1 {
      font-size: 150%;
    }

    td {
      padding-top: 5px;
      padding-bottom: 0px;
    }
  }
}

// Side scroller on vita page
ul.vita-cats {
  position: fixed;
  padding-top: 2em;
}

li.cat-item {
  font-size: 112.5%;
  a {color: lighten(black, 10%);}
  padding: 0 0 0 0.2em!important;
  margin: 0 !important;
}

li.cat-item.active {
  border-left: 0.2em solid lighten(black, 40%);
  padding: 0 !important;
}

.vita-nowrap {
  vertical-align: top;
  text-align: right;

  @include media-breakpoint-up(sm) {
    white-space: nowrap;
  }

}

.main-announcement {
  font-size: 200%;
  color: #0a304e;
  text-align: center;
}

.main-news {
  color: #0a304e;
  a {
    color: #0a304e;
  }

  ul {
    line-height: 80%;
    padding-left: 0.5em;
    margin-left:0;

    li {
      padding-bottom:0.25em;
    }
  }
}

.main-news-date {
  color: lighten(black, 50%);

}

.main-news-title {
  line-height: 0.5;
}

.main-description {
  font-size: 150%;
}

.news-date {
  font-size: 150%;
  color: #0a304e;
  text-align: left;
  vertical-align: center;
}

table.directory {
  width: 100%;
  border-collapse: collapse;

  th {
    border-bottom: 2px solid #111;
  }

  tr:hover {
    background-color: #eee;
  }

  a  {
     text-align: left;
	}
  td {
    text-align: left;
  }

  td.name {
    text-align: left;
  }
}

.responsive-chart {
  width: 100%;
}

.publication-item {
  display: flex;
  align-items: flex-start;
  margin-bottom: 20px;
}

.publication-info {
  flex: 1;
}

.publication-graphic {
  margin-left: 20px;
}

.publication-graphic img {
  max-width: 200px;
  height: auto;
}
